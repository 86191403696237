import React, { useEffect, useState } from 'react';
import PaymentView from './paymentView'
import { 
  CheckCircle,
  CheckSquare,
  BatteryCharging,
  Phone,
  Mail,
  MapPin,
  Globe
} from 'react-feather';
import {
  Dropdown,
  DropdownButton,
  ButtonGroup,
  ProgressBar,
  Image,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import '../styles/dashboardMainViewStyles.css';
import StaticModal from './common/modal/StaticModal';
import Recommendations from './mainView/Recommendations/Recommendations';
import userWspImage from '../assests/images/user-wsp.jpg';
import illustration1 from '../assests/images/illustration-1.svg';
import illustration2 from '../assests/images/illustration-2.svg';
import illustration3 from '../assests/images/illustration-3.svg';
import contactUsImage from '../assests/images/contactus.png';
import knowUsImage from '../assests/images/knowus.png';
import mobileImage1 from '../assests/images/mobile1.png';
import mobileImage2 from '../assests/images/mobile2.png';
import mobileImage3 from '../assests/images/mobile3.png';
import icono1 from '../assests/icons/icono1.svg';
import icono2 from '../assests/icons/icono2.svg';
import icono3 from '../assests/icons/icono3.svg';
import useWindowSize from './utils/windowSize';
import FloatingWhatsApp from 'react-floating-whatsapp'

export default function DashboardMainView(props) {
  const { 
    getBusStopsSaga, 
    busStops, 
    isLoadingBusStops,
    setUserDonation,
    donationSuccess,
    donationError
  } = props;
  const [busStopSelected, selectBusStop] = useState('');
  const [showResumeModal, setShowResumeModal] = useState(false);
  
  const size = useWindowSize();

  const handleBusStopSelection = (value) => {
    selectBusStop(value);
  };

  useEffect( () => {
    getBusStopsSaga();
  }, []);

  return (
    <div>
      <nav className="site-header sticky-top py-1">
        <div className="container d-flex flex-column flex-md-row justify-content-between">
          <p className="py-2 dashboard__supernova-text" href="#" aria-label="Product">
            SUPERNOVA ENCODER
          </p>
          <a className="py-2 d-none d-md-inline-block" href="#"></a>
          <a className="py-2 d-none d-md-inline-block" href="#"></a>
          <a className="py-2 d-none d-md-inline-block" href="ss#"></a>
          <a className="py-2 d-none d-md-inline-block" href="#"></a>
          <a className="py-2 d-none d-md-inline-block" href="#">
            {/* <BatteryCharging color='green' /> */}
          </a>
          <a className="py-2 d-none d-md-inline-block no-underline" href="#about-us">About us</a>
          <a className="py-2 d-none d-md-inline-block no-underline" href="#contact">Contact</a>
        </div>
      </nav>

      <div className="text-center">
        <div className="phone-wrapper">
          <div className='main'></div>
          <div className="phone">
            <div className="phone-screen">
              <div className="phone-top-noch"></div>
              <div>
                <Image className="illustration-3" src={illustration3} alt="illustration" />
              </div>
            </div>
          </div>
        </div>
        <Image className="illustration-1" src={illustration1} alt="illustration" />
        <Image className="illustration-2" src={illustration2} alt="illustration2" />
        <div className="body__donate-absolute-container">
          <h1 className="display-4 font-weight-bold dashboard__main-text">
            We are building the future
            <CheckCircle size={size.width < 775 ? 50 : 100} color='chartreuse' />
          </h1>
          
        </div>
      </div>

      <div className="d-md-flex flex-md-equal w-100 min-height-50vh">
        {isLoadingBusStops ?
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          :
          <div className="about-us text-center overflow-hidden body__money-box-conatiner">
            <div className="my-3 p-3">
              <div className="d-flex justify-content-center align-items-center">
                <h2 className="body__title"> High software quality assured </h2>
                <CheckCircle size={size.width < 775 ? 74 : 54} color='green' />
              </div>
              <p className="lead">
                Meet customer demands by complying with strict protocols, to deliver a product of excellence that exceeds customer expectations.
              </p>
              <p className="text-uppercase dashboard__pregress-subtitle">
                We deliver professional services of web and mobile development
              </p>
              {/* <div>
                <div className="body__progressbar-container">
                  <ProgressBar 
                    className="dashboard__progressbar"
                    striped 
                    variant="success" 
                    now={100} 
                    min={0} 
                    max={100}
                    animated
                  />
                  <div className="dashboard__progressbar-bus-text-circle">
                    <CheckCircle size={size.width < 775 ? 74 : 54} color='white' />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        }
      </div>

      <div id="recommendations" className="position-relative text-center">
        <Recommendations></Recommendations>
      </div>

      <div id="about-us" className="position-relative text-center">
        <div className='about-desktop'>
          <Image className="image-w100-desktop" src={knowUsImage} />
        </div>
        <div className='about-mobile'>
          <Image className="image-w100" src={mobileImage1} />
          <Image className="image-w100 luciano-mobile" src={mobileImage2} />
        </div>
      </div>

      <div className="d-md-flex flex-md-equal w-100 bg-secondary dashboard__payment-container">
        <div className="p-6 text-center text-white">
          <div className="my-3 py-3">
            <div className="d-flex justify-content-center align-items-center">
              <h2 className="body__title">Take action | build your future</h2>
              <div>
              <CheckSquare className='check-sqre-icon' color='green' />
              </div>
            </div>
            <div className='contact-container'>
              <p>Applying last technologies of the market</p>
              <div className='tech-icon-container'>
                <Image className="tech-icon" src={icono2} />
                <Image className="tech-icon" src={icono1} />
                <Image className="tech-icon" src={icono3} />
              </div>
              <p>Building Web pages with JavaScript frameworks</p>
              <p>Mobile apps for IOS and Android platform with React Native</p>
              {/* <p>Contact us</p>
              <p><Phone color='green' className='icon-right-space'/>+59890708876</p>
              <p><Mail color='blue' className='icon-right-space'/>luciano.fndz@gmail.com</p> */}
            </div>
            {/* {isLoadingBusStops ?
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              :
              <div>
                <DropdownButton
                  as={ButtonGroup}
                  key={'Info'}
                  id={'dropdown-variants-Info'}
                  variant={'info'}
                  title={'Bus Stop'}
                  onSelect={handleBusStopSelection}
                >
                  {busStops ?
                    busStops.busStops.map((item, index) => {
                      return (
                      <Dropdown.Item key={`${index}`} eventKey={`${index}`}>Bus Stop {item.name.toUpperCase()}</Dropdown.Item>
                      );
                    })
                    : <Dropdown.Item key={"0"} eventKey="1"> Not available yet </Dropdown.Item>
                  }
                </DropdownButton>
                <PaymentView 
                  busStop={busStopSelected} 
                  userDonationFunction={setUserDonation}
                  donationSuccess={donationSuccess}
                  donationError={donationError}
                />
              </div>
            } */}
          </div>
        </div>
      </div>

      <div>
        <FloatingWhatsApp className='wsp-icon' phoneNumber="+59892708876" accountName="Luciano" avatar={userWspImage}/>
      </div>

      <div id="contact" className="">
        <div className='contact-desktop'>
          <Image className="image-w100-desktop" src={contactUsImage} />
        </div>
        <div className='contact-mobile'>
          <Image className="image-w100" src={mobileImage3} />
        </div>
      </div>

      <StaticModal show={showResumeModal} handleClose={() => setShowResumeModal(false)}></StaticModal>

      <footer className="container py-5">
        <Row>
          <Col xxs={12} md={4}>
            <div className='linkedin-wrapper'>
              <Button variant="primary" onClick={() => setShowResumeModal(true)}>
                Get Luciano Fernandez CV
              </Button>
            </div>
            <div className='linkedin-wrapper'>
              <a href="https://www.linkedin.com/in/lucianomfernandez/" target="_blank">
                <Image className="linkedinImage" src="https://fatimamartinez.es/wp-content/uploads/2019/02/Linkedin-logo.png" alt="linkedin"/>
              </a>
            </div>
          </Col>
          <Col xxs={12} md={4}>
            <h5>Location</h5>
            <ul className="list-unstyled text-small">
              <li><span className="text-muted">Montevideo, Uruguay<MapPin color='red'className='icon-left-space '/></span></li>
              <li><span className="text-muted">We deliver to the world<Globe color='green' className='icon-left-space '/></span></li>
            </ul>
          </Col>
          <Col xxs={12} md={4}>
            <h5>Contact us</h5>
            <ul className="list-unstyled text-small">
              <li><span className="text-muted">luciano.fndz@gmail.com</span></li>
              <li><span className="text-muted">+59892708876</span></li>
            </ul>
          </Col>
        </Row>
      </footer>

    </div>
  );
};