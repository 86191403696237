import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import carrousel1 from '../../../assests/images/carrousel1-min.png';
import carrousel2 from '../../../assests/images/carrousel2-min.png';
import carrousel3 from '../../../assests/images/carrousel3-min.png';
import './RecommendationsStyles.css';

function Recommendations() {
  return (
    <Carousel interval={1000} className='carrousel' variant="dark">
      <Carousel.Item>
        <Image src={carrousel1} className='carrousel-item'/>
      </Carousel.Item>
      <Carousel.Item>
        <Image src={carrousel2} className='carrousel-item'/>
      </Carousel.Item>
      <Carousel.Item>
        <Image src={carrousel3} className='carrousel-item'/>
      </Carousel.Item>
    </Carousel>
  );
}

export default Recommendations;