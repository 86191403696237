import axios from 'axios';

export const getAllBusStops = () => {
  return new Promise((resolve, reject) => {
    // axios
    //   .get('jsonFiles/busStopApi.json')
    //   .then(({ data }) => { 
    //     resolve(data);
    //   })
    //   .catch((err) => {
    //     reject(err);
    //   });
    const data = {
      "objectiveTarget": 700,
      "busStops": [
          {
              "name": "a",
              "amount": 50,
              "lastAmount": 30,
              "lastDonatorName": "",
              "lastDonatorEmail": ""
          },
          {
              "name": "b",
              "amount": 300,
              "lastAmount": 40,
              "lastDonatorName": "",
              "lastDonatorEmail": ""
          },
          {   "name": "c", 
              "amount": 150,
              "lastAmount": 70,
              "lastDonatorName": "",
              "lastDonatorEmail": ""
          },
          {
              "name": "d",
              "amount": 400,
              "lastAmount": 230,
              "lastDonatorName": "",
              "lastDonatorEmail": ""
          },
          {   "name": "e", 
              "amount": 450,
              "lastAmount": 30,
              "lastDonatorName": "",
              "lastDonatorEmail": ""
          }
      ]
  };
    resolve(data)
  });
};

// Commented because is made with localStorage on Saga file
// and have no api to post
export const setDonation = (value) => {
  return new Promise((resolve, reject) => {
  //   axios
  //     .post('jsonFiles/busStopApi.json', data)
  //     .then(({ data }) => { 
  //       resolve(data);
  //     })
  //     .catch((err) => {
  //       reject(err);
  //     });
  });
};